/* global Component */
class cartNotificationComponent extends Component {

    static name() {
        return "cartNotificationComponent";
    }

    static componentName() {
        return "cartNotificationComponent";
    }

    data() {
        return {
            visible: false,
            qty: 0,
            item: null
        };
    }

    mounted() {
        return function () {
            window.$Event.$on('addItemToCart',(item)=>{
                this.visible = true;
                this.qty = item.carCant;
                this.item = item.Name;
                setTimeout(()=> this.visible = false , 1500);
            });
        };
    }

    getMethods() {
        return {
            setQtyText: this.setQtyText
        };
    }

    setQtyText(qty) {
        return `Agregaste ${qty} ${qty > 1 ? 'artículos' : 'artículo'}  a tu carrito`;
    }

    getTemplate() {
        return ` <transition name="slide-fade" v-if="visible">
                    <div class="cart-notification">
                        <i class="success icon fas fa-check-circle"></i>
                        <span class="success">{{setQtyText(qty)}}</span>
                        <p class="item-name">{{item}}</p>
                    </div>
                </transition>`;
    }
}

cartNotificationComponent.registerComponent();